


















































































































































import { BModal, BTable, BLink, BButton, BCard, BOverlay, BCol, BContainer, BRow } from "bootstrap-vue";
import { Component, Prop, Vue } from "vue-property-decorator";

import EntityTable from "../../entity/EntityTable.vue";

import projectModel from "@/api/project.model";
import projectCommentModel from "@/api/project_comment.model";
import ProjectBudget from "./ProjectBudget.vue";
import MonthRangeSelector from "./MonthRangeSelector.vue";

@Component({
	components: {
		BModal,
		BTable,
		BLink,
		BButton,
		BCard,
		BOverlay,
		EntityTable,
		ProjectBudget,
		MonthRangeSelector,
		BContainer,
		BRow,
		BCol,
	},
})
export default class ProjectPreview extends Vue {
	@Prop() id: string;
	model: any = projectModel;

	commentModel = projectCommentModel;
	commentFormColumns = ["projectField", "description"];
	commentTitle = "Avaliaçōes";

	isEntityLoading = false;
	entityDescription: any = [];
	entity: any = {};
	isModalOpened = false;

	get defaultCommentFilter(): any {
		return { "ProjectComment.projectId": this.id };
	}

	get formColumns() {
		return this.model.formColumns;
	}

	get formDescriptions() {
		return this.entityDescription.filter((field: any) =>
			this.formColumns.map(({ key }: any) => key).includes(field.key),
		);
	}

	async mounted() {
		await this.loadEntity();
		this.transformSelectTablesValues();
	}

	getMonitoringPlanStepsTableItems(descriptionKey: string) {
		return this.entity[descriptionKey]
			.map(({ activities, ...item }: any) => activities.map((activity: any) => ({ step: item.step, ...activity })))
			.flat(1);
	}

	toggleModal() {
		this.isModalOpened = !this.isModalOpened;
	}

	getMultiselectFieldName(description: any): string {
		return (
			description.options
				.filter((option: any) => this.entity[description.key]?.includes(option.value))
				?.map(({ name }: any) => name)
				.join(", ") ?? ""
		);
	}

	getSelectFieldName(description: any): string {
		return description.options.find((option: any) => option.value === this.entity[description.key])?.name ?? "";
	}

	getCountiesNames(): string {
		return (
			this.entity?.executionCounties?.map((executionCounty: any) => executionCounty.county.name).join(", ") ?? ""
		);
	}

	goToForm(fieldIndex: number) {
		let step = 1;
		if ([6, 7, 8, 9, 10, 11].includes(fieldIndex)) {
			step = 2;
		} else if ([12, 13, 14].includes(fieldIndex)) {
			step = 3;
		} else if (fieldIndex === 15) {
			step = 4;
		}

		this.$router.push(`${this.$router.currentRoute.path}/etapa/${step}`);
	}

	transformSelectTablesValues() {
		const projectRelationsFieldsKeys = ["monitoringPlanSteps", "responsibleMembers", "communicationPlanIndicators"];

		for (const fieldKey of projectRelationsFieldsKeys) {
			// Descrição das propriedades da tabela
			const entityValueDescriptions = Object.fromEntries(
				Object.values(this.entityDescription.filter(({ join }: any) => join === fieldKey)).map((value: any) => [
					value.key.substring(fieldKey.length + 1, value.key.length),
					value,
				]),
			);
			this.entity[fieldKey] = this.entity[fieldKey].map((entityValue: any) =>
				Object.fromEntries(
					Object.entries(entityValue).map(([key, value]) => {
						if (entityValueDescriptions[key]?.kind === "select") {
							return [
								key,
								entityValueDescriptions[key].options.find(({ value: optionValue }: any) => optionValue === value)
									.name,
							];
						}

						return [key, value];
					}),
				),
			);
		}
	}

	getToProjectTable() {
		this.$router.replace(this.$router.currentRoute.path.replace(`/${this.id}`, ""));
	}

	async loadEntity() {
		this.isEntityLoading = true;
		try {
			this.entity = await this.model.readPreviewProject(this.id);
			this.entityDescription = this.model.description;
		} catch (error) {
			console.error(error);
		}
		this.isEntityLoading = false;
	}
}
