




















import { Component, Prop, Vue } from "vue-property-decorator";

import projectModel from "@/api/project.model";
import ProjectBudgetEditTable from "./ProjectBudgetEditTable.vue";
import { BOverlay } from "bootstrap-vue";
import { AlertKind, showAlert } from "@/helpers";
import ProjectBudgetTotals from "./ProjectBudgetTotals.vue";

@Component({
	components: {
		ProjectBudgetEditTable,
		ProjectBudgetTotals,
		BOverlay,
	},
})
export default class ProjectBudget extends Vue {
	@Prop({ required: true }) id: string;
	model = projectModel;
	isLoading = false;
	entity: any = {};

	get budgetFieldsDescription() {
		return Object.fromEntries(
			Object.values(this.model.description.find(({ key }: any) => key === "budgetItems.budgetField").options).map(
				(field: any) => [field.value, field],
			),
		);
	}

	get fieldsDescription() {
		return Object.fromEntries(
			Object.values(this.model.description.filter((field: any) => field?.join === "budgetItems")).map(
				(value: any) => {
					const keyName = value.key.substring("budgetItems".length + 1, value.key.length);

					return [keyName, { ...value, key: keyName }];
				},
			),
		);
	}

	async created() {
		try {
			this.isLoading = true;
			this.entity = await projectModel.getStep(this.id, "4");
		} catch (error: any) {
			showAlert(AlertKind.ERROR, "Ocorreu um erro. Tente novamente.");
			this.$emit("onLoadError");
		} finally {
			this.isLoading = false;
		}
	}
}
