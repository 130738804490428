















import { Component, Vue, Watch } from "vue-property-decorator";

import projectModel from "@/api/project.model";
import EntityForm from "../entity/EntityForm.vue";
import ProjectPreview from "./components/ProjectPreview.vue";
import EntityTable from "../entity/EntityTable.vue";

@Component({
	components: { EntityForm, ProjectPreview, EntityTable },
})
export default class Projects extends Vue {
	model: any = projectModel;

	title = "Projetos";
	tableColumns = [
		"title",
		"status",
		{ name: "Email do proponente", key: "proponent.user.email" },
	];

	showEntityForm = false;
	enableCreation = false;
	id = "";

	async mounted() {
		this.id = this.$route.params.id;
	}

	@Watch("$route")
	async onRouteChange() {
		this.id = this.$route.params.id;
	}
}
